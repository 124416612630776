#PicNameTechContainer {
    display: flex;
    justify-content: stretch;
}

/* #ProjectPic {
    flex: 1;
    /* border: solid black 1px; 
} */

#ProjectName {
    flex: 2;
    /* border: solid black 1px; */
}

#ProjectTech {
    flex: 1;
    /* border: solid black 1px; */
}