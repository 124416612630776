body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-family: "WorkSans";
}

p {
  font-family: "WorkSans";
  font-size: 1.12em;
}

li {
  font-family: "WorkSans";
  font-size: 1.08em;
}


h1 {
  font-size: 4em;
  font-family: "ForumRegular";
}

h2 {
  font-size: 3em;
  font-family: "ForumRegular";
}

h3 {
  font-size: 1.7em;
  font-family: "ForumRegular";
}

h4 {
  font-size: 1.15em;
  font-family: "ForumRegular";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ForumRegular';
  src: local('ForumRegular'),
    url('./fonts/Forum-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'GotuRegular';
  src: local('GotuRegular'),
    url('./fonts/Gotu-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans'),
    url('./fonts/WorkSans-VariableFont_wght.ttf') format('ttf');
}

:root {
  --primary-color: #E07A5F;
  --secondary-color: #81B29A;
  --tertiary-color: #F2CC8F;
  --font-color: #3D405B;
  --bg-color: #F4F1DE;
  --heading-color: #3D405B;
  --border-color: var(--secondary-color);
  --shadow-color: var(--secondary-color);
  --card-background-color: var(--tertiary-color);
}

a {
  color: var(--font-color);
}