#NavBar {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

#TextContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 92%;
}

#NavTextContainer {
    margin-left: 3%;
    margin-right: 3%;
}

.MuiButton-outlined {
    background-color: var(--bg-color);
    border-color: var(--font-color);
    color: var(--font-color);
}