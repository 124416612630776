#RoleCompanyDatesContainer {
    display: flex;
    align-items: flex-end;
}

#RoleContainer {
    flex: 1;
    /* border: solid black 1px; */
}

#CompanyContainer {
    flex: 1;
    /* border: solid black 1px; */
}

#DatesContainer {
    flex: 1;
    /* border: solid black 1px; */
}