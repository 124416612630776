#AboutTopLeftQuadrant {
    display: flex;
    /* background-color: pink; */
    clear: both;
}

#AboutPhotoContainer {
    height: 85%;
    width: 70%;
    margin: 12.5% 12.5% 0% 25%;
    vertical-align: middle;
    clear: both;
    /* background-color: white; */
    /* background-color: white; */
}