#Projects {
    min-height: 100vh;
    width: 100vw;
    display: inline;
    clear: both;
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: space-around; */
}

#ProjectsTitleContainer:nth-of-type(1) {
    /* flex-basis: 5vh; */
    /* flex: 1; */
}


/* 
#ProjectContainer:nth-of-type(2) {
    background-color: green;
}

#ProjectContainer:nth-of-type(3) {
    background-color: greenyellow;
}

#ProjectContainer:nth-of-type(4) {
    background-color: yellow;
}

#ProjectContainer:nth-of-type(5) {
    background-color: orange;
} */