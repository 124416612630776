#IntroContent {
    margin-top: 15vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* #BorderBox {
    height: 75%;
    border-right: 2px solid black;
} */

#IntroParagraph {
    padding-bottom: 3em;
    flex: 1;

}

#PictureContainer {
    flex: 1;
}