#ExperienceContainer {
    display: flex;
    flex-direction: column;
    margin: .5em auto .5em auto;
    width: 85%;
}

#RoleCompanyDatesContainer {
    flex: 1;
    border-bottom: solid black 1px;
}

#BulletsContainer {
    flex: 2;
    /* border: solid black 1px; */
}