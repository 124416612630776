#LandingPage {
    min-height: 80vh;
    width: 90vw;
    display: flex;

    flex-direction: column;
    justify-content: center;
    flex-basis: fit-content;
    margin: auto;
    clear: both;
}

#NavBar {
    flex: .5;
    margin-top: 1em;
}

#WelcomeContainer {
    /* flex: 2; */
}

#IntroContent {
    flex: 5;
}