#Experience {
    min-height: 100vh;
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: auto;
    clear: both;
}

#ExperienceTitleContainer:nth-of-type(1) {
    /* flex-basis: 5vh; */
    flex: 1;
}

#ExperienceContainer {
    /* flex-basis: 20vh; */
    flex: 2;
    /* border: solid black 1px; */

}