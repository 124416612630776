#IntroHeader {
    text-align: right;
}

#BorderBox {
    margin: 0 8% 0 0;
}

#IntroTextBlock {

    /* padding-top: 3em; */
    text-align: right;
}