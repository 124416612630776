#ContactPage {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin: auto;
    clear: both;
}

#ContactContainer {
    flex: 7.5;
    clear: both;
    /* background-color: peachpuff; */
}

#CopyrightContainer {
    flex: 1.5;
    /* background-color: aqua; */
}