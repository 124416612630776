.App {
  text-align: center;


}

body {
  background-color: var(--bg-color);
  color: var(--font-color);
}

.App-header {
  font-family: "GotuRegular";
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: fit-content;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-section {
  font-family: "ForumRegular";
}

.App-link {
  color: #61dafb;
}

#LandingPage {
  /* background-color: var(--primary-color); */
}

#Experience {
  /* background-color: var(--primary-color); */
}

#ContactPage {
  /* background-color: var(--primary-color); */
}