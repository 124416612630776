#AboutRightContainer {
    height: 100vh;
    /* background-color: yellow; */
    display: flex;
    /* border: solid black 1px; */
    clear: both;
}

#AboutParagraphContainer {
    height: 70%;
    width: 65%;
    /* background-color: white; */
    /* margin: 18% 12.5% 22.2% 12.5%; */
    margin: 12% 23% auto 12%;
    /* border: solid black 1px; */
}