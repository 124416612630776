#AboutPage {
    min-height: 100vh;

    width: 100vw;
    display: flex;
    margin: auto;
    clear: both;
}

#AboutLeftContainer {
    flex: 1;
    max-width: 50%;
}

#AboutRightContainer {
    flex: 1;
}