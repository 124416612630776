#SmallContainer {
    min-height: 10em;
    width: 75%;
    margin: 1em auto 1em auto;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    border: 5px solid var(--border-color);
    /* background-color: var(--card-background-color); */
    border-radius: 1% / 7%;
    transition: 1s;
}


#SmallContainer:hover {
    min-height: 10.5em;
    width: 75.5%;

    box-shadow: 10px 5px 10px var(--shadow-color);
}

#PicNameTechContainer {
    flex: 1;
    /* background-color: aqua; */
}

#BriefDescriptionContainer {
    flex: 1;
    /* background-color: tan; */
}