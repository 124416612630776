#AboutBottomLeftContainer {
    display: flex;
    margin: auto;
}

#AboutSubtitleContainer {
    height: 44.4%;
    width: 62.5%;
    /* background-color: aliceblue; */
    margin: 5% 12.5% auto 25%;
    border: solid black 1px;
}