#AboutLeftContainer {
    margin: 0;
    vertical-align: middle;
    clear: both;
    /* display: flex;
    flex-direction: column;
    justify-content: space-evenly; */
    /* border: solid black 1px; */
}

/* #AboutTopLeftQuadrant {
    flex: 1;
    height: 50%;
    /* background-color: pink; 
    border: solid black 1px;
}

#AboutBottomLeftQuadrant {
    flex: 1;
    height: 50%;
    /* background-color: orange; 
    border: solid black 1px;
} */